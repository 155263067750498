import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { catchError, Observable, throwError } from 'rxjs';
import { MawAuthService } from '@medappsware/maw-auth';
import { MawAuthUtils } from '@medappsware/maw-auth';
import { environment } from '../../../../environments/environment';

export const apiUrls = {
    signIn: 'auth/signin',
    refreshToken: 'auth/refresh'
  };

@Injectable()
export class AuthInterceptor implements HttpInterceptor
{
    /**
     * Constructor
     */
    constructor(private _authService: MawAuthService)
    {
    }

    /**
     * Intercept
     *
     * @param req
     * @param next
     */
    intercept(
        req: HttpRequest<any>,
        next: HttpHandler
      ): Observable<HttpEvent<any>> {
        // Clone the request object
        let newReq = req.clone();
        //console.log('route', this.route.data)
        // Request
        //
        // If the access token didn't expire, add the Authorization header.
        // We won't add the Authorization header if the access token expired.
        // This will force the server to return a "401 Unauthorized" response
        // for the protected API routes which our response interceptor will
        // catch and delete the access token from the local storage while logging
        // the user out from the app.
        if (
          this._authService.accessToken &&
          !MawAuthUtils.isTokenExpired(this._authService.accessToken)
        ) {
          newReq = req.clone({
            headers: req.headers.set(
              'Authorization',
              'Bearer ' + this._authService.accessToken
            ),
          });
        }
    
        // Response
        return next.handle(newReq).pipe(
          catchError((error: any) => {
            // Catch "401 Unauthorized" responses
            if (
              !req.url.includes(apiUrls.signIn) &&
              error instanceof HttpErrorResponse &&
              error.status === 401
            ) {
                // Refresh the access token
                if(this._authService.refreshToken){
                  let apiUrl = environment.authApiUrl + apiUrls.refreshToken;
                  this._authService.signInUsingToken(apiUrl).subscribe({
                      next: (res: any) => {
                          console.log('access token refreshed')
                      },
                      error: (error: any) => {
                          if(error && error.error){
                              this._authService.signOut();
                          }
                      }
                  });
                }else{
                    this._authService.signOut();
                    // Reload the app
                    console.log("relaoding app")
                    location.reload();
                }
            }
    
            return throwError(error);
          })
        );
      }
}
