.mat-ripple{
    overflow:hidden;
    position:relative
}
.mat-ripple:not(:empty){
    transform:translateZ(0)
}
.mat-ripple.mat-ripple-unbounded{
    overflow:visible
}
.mat-ripple-element{
    position:absolute;
    border-radius:50%;
    pointer-events:none;
    transition:opacity,transform 0ms cubic-bezier(0, 0, 0.2, 1);
    transform:scale3d(0, 0, 0);
    background-color:var(--mat-ripple-color, rgba(0, 0, 0, 0.1))
}
.cdk-high-contrast-active .mat-ripple-element{
    display:none
}
.cdk-visually-hidden{
    border:0;
    clip:rect(0 0 0 0);
    height:1px;
    margin:-1px;
    overflow:hidden;
    padding:0;
    position:absolute;
    width:1px;
    white-space:nowrap;
    outline:0;
    -webkit-appearance:none;
    -moz-appearance:none;
    left:0
}
[dir=rtl] .cdk-visually-hidden{
    left:auto;
    right:0
}
.cdk-overlay-container,.cdk-global-overlay-wrapper{
    pointer-events:none;
    top:0;
    left:0;
    height:100%;
    width:100%
}
.cdk-overlay-container{
    position:fixed;
    z-index:1000
}
.cdk-overlay-container:empty{
    display:none
}
.cdk-global-overlay-wrapper{
    display:flex;
    position:absolute;
    z-index:1000
}
.cdk-overlay-pane{
    position:absolute;
    pointer-events:auto;
    box-sizing:border-box;
    z-index:1000;
    display:flex;
    max-width:100%;
    max-height:100%
}
.cdk-overlay-backdrop{
    position:absolute;
    top:0;
    bottom:0;
    left:0;
    right:0;
    z-index:1000;
    pointer-events:auto;
    -webkit-tap-highlight-color:rgba(0,0,0,0);
    transition:opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
    opacity:0
}
.cdk-overlay-backdrop.cdk-overlay-backdrop-showing{
    opacity:1
}
.cdk-high-contrast-active .cdk-overlay-backdrop.cdk-overlay-backdrop-showing{
    opacity:.6
}
.cdk-overlay-dark-backdrop{
    background:rgba(0,0,0,.32)
}
.cdk-overlay-transparent-backdrop{
    transition:visibility 1ms linear,opacity 1ms linear;
    visibility:hidden;
    opacity:1
}
.cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing{
    opacity:0;
    visibility:visible
}
.cdk-overlay-backdrop-noop-animation{
    transition:none
}
.cdk-overlay-connected-position-bounding-box{
    position:absolute;
    z-index:1000;
    display:flex;
    flex-direction:column;
    min-width:1px;
    min-height:1px
}
.cdk-global-scrollblock{
    position:fixed;
    width:100%;
    overflow-y:scroll
}
textarea.cdk-textarea-autosize{
    resize:none
}
textarea.cdk-textarea-autosize-measuring{
    padding:2px 0 !important;
    box-sizing:content-box !important;
    height:auto !important;
    overflow:hidden !important
}
textarea.cdk-textarea-autosize-measuring-firefox{
    padding:2px 0 !important;
    box-sizing:content-box !important;
    height:0 !important
}
@keyframes cdk-text-field-autofill-start{
    /*!*/
}
@keyframes cdk-text-field-autofill-end{
    /*!*/
}
.cdk-text-field-autofill-monitored:-webkit-autofill{
    animation:cdk-text-field-autofill-start 0s 1ms
}
.cdk-text-field-autofill-monitored:not(:-webkit-autofill){
    animation:cdk-text-field-autofill-end 0s 1ms
}
.mat-focus-indicator{
    position:relative
}
.mat-focus-indicator::before{
    top:0;
    left:0;
    right:0;
    bottom:0;
    position:absolute;
    box-sizing:border-box;
    pointer-events:none;
    display:var(--mat-focus-indicator-display, none);
    border:var(--mat-focus-indicator-border-width, 3px) var(--mat-focus-indicator-border-style, solid) var(--mat-focus-indicator-border-color, transparent);
    border-radius:var(--mat-focus-indicator-border-radius, 4px)
}
.mat-focus-indicator:focus::before{
    content:""
}
.cdk-high-contrast-active{
    --mat-focus-indicator-display: block
}
.mat-mdc-focus-indicator{
    position:relative
}
.mat-mdc-focus-indicator::before{
    top:0;
    left:0;
    right:0;
    bottom:0;
    position:absolute;
    box-sizing:border-box;
    pointer-events:none;
    display:var(--mat-mdc-focus-indicator-display, none);
    border:var(--mat-mdc-focus-indicator-border-width, 3px) var(--mat-mdc-focus-indicator-border-style, solid) var(--mat-mdc-focus-indicator-border-color, transparent);
    border-radius:var(--mat-mdc-focus-indicator-border-radius, 4px)
}
.mat-mdc-focus-indicator:focus::before{
    content:""
}
.cdk-high-contrast-active{
    --mat-mdc-focus-indicator-display: block
}
html{
    --mat-ripple-color:rgba(0, 0, 0, 0.1)
}

@import 'components/accordion.scss';
@import 'components/dialog.scss';
@import 'components/form.scss';
@import 'components/menu.scss';
@import 'components/progress-bar.scss';
@import 'components/tabs.scss';
@import 'components/dashboard.scss';
@import 'components/data-grid.scss';
@import 'components/tree.scss';
@import 'components/list-management.scss';
@import 'components/grid-detail.scss';