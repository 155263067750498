.mat-drawer-container{
    height: calc(100% - 88px);
}

.mat-drawer{
    min-width: 250px;
    background-color: #fff !important;
    border-right: 1px solid #ccc !important;
    .nav-bar{
        ul{
            padding: 15px;
            margin: 0;
            li{
                list-style: none;
                margin-bottom: 6px;
                a{
                    text-decoration: none;
                    font-size: 0.96rem;
                    color: #000;
                    cursor: pointer;
                    margin-left: 0;
                    border-radius: 0;
                    border: 1px solid #a1c2fa30;
                    border-left: 2px solid #a1c2fa30;
                    padding: 2px 10px;
                    text-transform: capitalize;
                    font-feature-settings: "smcp";
                    font-variant: small-caps;
                    opacity: 1!important;
                    display: block;
                    &:hover{
                        background-color: rgba(30, 41, 59, 0.05);
                    }
                    &.active{
                        background: #f0f5f9;
                        border-left: 2px solid #1155cc;
                    }
                }
                ul{
                    padding: 0px;
                    margin: 10px 0 0 10px;
                }
            }
        }
    }
}
app-dc-portal{
    .mat-drawer{
        min-width: 300px;
        --mat-sidenav-container-width: 300px;
    }
}
.mat-drawer-content{
    z-index: 3 !important;
    overflow: overlay !important;
    scrollbar-gutter: stable;
    padding-top: 1.5em;
}
::-webkit-scrollbar {
    width: 8px;
    border: none;
    background: transparent;
}
 
::-webkit-scrollbar-track {
    box-shadow: none;
    border: none;
}
 
::-webkit-scrollbar-thumb {
  background-color: #646464;
  opacity: 0.6;
  cursor: pointer;
  &:hover {
    opacity: 1;
  }
}
.w-full{
    width: 100%;
}
.bordered{
    border: 1px solid #ccc;
}
.form-bg{
    background: #f0f5f9 !important;
}
.overflow-x-hidden{
    overflow-x: hidden;
}
a.portal-link {
    position: absolute;
    top: 49px;
    left: 15px;
    z-index: 5;
}