.cursor-pointer {
    cursor: pointer;  
}
h1{
    font-size: 2.5em;
}
h2{
    font-size: 2em;
}
h3{
    font-size: 1.5em;
}
a{
    color: #1155cc;
    &:hover{
        color: #1155cc;
    }
    &.bordered-link{
        border-bottom: 1px solid #1155cc;
        &:hover{
            text-decoration: none;
        }
    }
    &:not([href]):not([class]){
        color: #1155cc;
        cursor: pointer;
    }
    &.hover-black{
        &:hover{
            color: #000;
        }
        &.bordered-link{
            &:hover{
                border-bottom: 1px solid #000;
            }
        }
    }
}
pre{
    background-color: #efefef;
}
.mark, mark {
    padding: 0.2em;
    background-color: #f0f5fe;
}
ol li {
    list-style: decimal-leading-zero;
    margin-left: 22px;
}

.font-small, .font-small * {
    font-size: 0.75rem !important;
}

.file-icon{
    &.fa-file-pdf-o{
        color: #AF0000;
    }
    &.fa-file-excel-o{
        color: #1F7A1F;
    }
    &.fa-file-word-o{
        color: #2B579A;
    }
    &.fa-file-powerpoint-o{
        color: #D24726;
    }
    &.fa-file-photo-o{
        color: #FFA500;
    }
    &.fa-file-zip-o{
        color: #A52A2A;
    }
    &.fa-file-audio-o{
        color: #eccb13;
    }
    &.fa-file-video-o{
        color: #FF4500;
    }
    &.fa-file-text-o{
        color: #1155cc;
    }
}
.file-icon{
    height: 20px;
    width: 20px;
    border-radius: 2px;
    display: inline-block;
    vertical-align: middle;
    color: #fff;
    line-height: 21px;
    &.pdf{
        background-color: #AF0000;
    }
    &.xls{
        background-color: #1F7A1F;
    }
    &.doc{
        background-color: #2B579A;
    }
    &.ppt{
        background-color: #D24726;
    }
    &.img{
        background-color: #FFA500;
    }
}
iframe{
    background-color: #fff;
    width: 100%;
    height: calc(100vh - 240px);
    box-shadow: none;
    border: 1px solid #ccc;
}

.maw-breadcrumb{
    .mat-icon {
        height: 18px;
        width: 18px;
        text-align: center;
        font-size: 18px;
        vertical-align: middle;
    }
    a{
        color: #1a73e8;
        text-decoration: none;
        cursor: pointer;
        &:hover{
            text-decoration: underline;
        }
    }
}