/* Bootstrap Optimized CSS */
@import './assets/thirdparty/bootstrap.min.css';

/* Core Grid Optimized CSS */
@import './assets/thirdparty/ag-grid.min.css';
/* Quartz Theme Specific CSS */
@import './assets/thirdparty/ag-theme-quartz.min.css';

/* You can add global styles to this file, and also import other style files */
@import "./assets/colors.scss";
@import "./assets/fonts.scss";
@import "./assets/constants.scss";

@import './assets/global.scss';
@import './assets/components.scss';