import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';

import { MainAppService } from '@medappsware/shared';
import { environment } from '../environments/environment';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})

export class AppComponent {
  sideNav: boolean = false;
  title = 'Fitness Labs';
  constructor(private mainAppService: MainAppService){
      this.mainAppService.setAPIURL(environment.apiURL);
  }
}
