import { Routes } from '@angular/router';
import { MawNoAuthGuard, authGuardChildRouteGuard, authGuardRouteGuard } from '@medappsware/maw-auth';
import { environment } from '../environments/environment';


export const routes: Routes = [
    {
        path: '',
        canActivate: [MawNoAuthGuard],
        canActivateChild: [MawNoAuthGuard],
        loadChildren: () => import('./components/front-end/front-end.routes').then(m => m.FrontEndRoutes)
    },
    {
        path: '',
        canActivate: [authGuardRouteGuard],
        canActivateChild: [authGuardChildRouteGuard],
        data: { refreshToken: environment.authApiUrl + 'auth/refresh' },
        loadChildren: () => import('./components/maw-template/maw-template.routes').then(m => m.MainTemplateRoutes)
    }

];
