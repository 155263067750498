app-filter-header-rendrer, select-header-renderer{
  width: 100%;
}
.filter-inputs{
  width: 100%;
  padding: 0px 0px;
  .mat-mdc-form-field{
    .mat-mdc-form-field-subscript-wrapper{
      display: none;
    }
  }
  .mat-icon{
    height: 15px !important;
    width: 20px !important;
    font-size: 18px !important;
    color: #666 !important;
    padding: 0px !important;
  }
  .filter-field{
    border-top: 1px solid #ddd;
    .mat-mdc-select-arrow svg {
      top: 4px;
    }
  }
  .grid-label{
    height: 32px;
    line-height: 32px;
    text-align: center;
    padding-left: 5px;
    padding-right: 8px;
    color: #000;
  }
  .mat-mdc-form-field{
    width: calc(100% - 2px);
    .mat-mdc-text-field-wrapper.mdc-text-field--outlined{
      *{
        border-color: transparent !important;
      }
      .mat-mdc-form-field-infix {
        min-height: 32px;
        padding-top: 5px;
        padding-bottom: 0px;
        padding-left: 5px;
        padding-right: 5px;
      }
    }
    
  }
}
.ag-root{
  font-family: 'Karla', sans-serif !important;
}
[class*=ag-theme-]{
  font-size: 0.9em;
}
.ag-header{
  background: #f5f5f5;
  color: #000;
  height: 66px !important;
  min-height: 66px !important;
  .ag-checkbox.ag-header-select-all {
    align-items: self-start;
    margin: 0px !important;
    width: 100%;
    text-align: left;
    justify-content: center;
    height: 100%;
    padding: 6px;
    &:after {
      content: "";
      height: 33px;
      width: calc(100% + -0px);
      position: absolute;
      background: #fff;
      bottom: 0;
      left: 0;
      border-top: 1px solid #ddd;
    }
    &:before {
        content: '';
        position: absolute;
        top: 0px;
        left: 0;
        width: 100%;
        height: 32px;
        border: 2px solid #fff;
    }
    .ag-checkbox-input-wrapper.ag-indeterminate:after{
      content: '';
    }
  }
  .ag-checkbox.ag-header-select-all:not(.ag-hidden) ~ .ag-header-cell-comp-wrapper {
    display: none;
  }
  .ag-header-row{
    color: #000;
    height: 100% !important;
    font-size: 1em;
    font-weight: 400;
    .ag-header-cell, .ag-header-group-cell {
      padding-left: 0px;
      padding-right: 0px;
      height: 100% !important;
    }
    .ag-header-group-cell-label, .ag-header-cell-label {
      align-items: start;
      justify-content: center;
    }
    .ag-header-cell:not(.ag-header-cell-auto-height) .ag-header-cell-comp-wrapper {
      align-items: start;
    }
    .ag-cell-label-container {
      padding: 0;
      line-height: 32px;
      position: relative;
      text-align: center;
      .ag-header-cell-text {
        display: block;
        width: calc(100% - 0px);
        border: 2px solid #fff;
        height: 32px;
        padding: 0px 8px 0 5px;
        margin-left: 0px;
      }
      &:after {
        content: '';
        height: 33px;
        width: calc(100% - 0px);
        position: absolute;
        background: #fff;
        bottom: 0px;
        left: 0px;
        border-top: 1px solid #ddd;
      }
    }
    .ag-header-cell.ag-column-first{
      .filter-inputs .grid-label, .ag-cell-label-container{
        text-align: left;
      }
    }
  }
  .ag-header-cell-resize {
    right: 0px;
    width: 1px;
    &::after{
      background: #ccc;
      width: 1px;
      height: 100%;
      top: 0px;
      left: 0px !important;
    }
  }
}

.ag-root-wrapper{
  border-radius: 0px;
}
table {
  width: 100%;
}

.header-filter{
  width: 200px;
  padding-right: 5px;
}
.ag-cell{
  padding: 0 6px !important;
}
.ag-drag-handle{
  background: #a1c2fa30;
  margin-left: -6px;
  padding: 0 6px;
  .ag-icon-grip::before {
    content: '\e945';
    font-family: 'Material Symbols Sharp';
    color: #cf5050;
    font-size: 18px;
  }
}

.ag-cell-inline-editing {
  border-color: #000 !important;
  border-radius: 0px;
  background: #fff;
  .ag-cell-edit-wrapper {
    height: 100% !important;
    border-radius: 0px;
    .ag-input-field-input {
      border: none !important;
      border-radius: 0px !important;
      outline: none !important;
      box-shadow: none !important;
    }
  }
}

checkbox-cell-rendrer {
  display: block;
  height: 100%;
  .checkbox-row {
    text-align: center;
    display: block;
    height: 100%;
    .mat-mdc-checkbox {
      margin: 0px;
    }
  }
}
btn-cell-renderer{
  text-align: center;
  width: 100%;
  display: block;
  button{
    margin-right: 0px !important;
  }
  button ~ button {
    margin-left: 6px;
  }
}

.pagination {
  border-radius: 0px;
  border: 1px solid #000;
  border-top: 0px;
  justify-content: center;
  background: #fff;
  .mat-mdc-unelevated-button{
    padding: 0px 10px;
    min-width: auto;
    margin: 5px;
    font-size: 1rem;
    font-family: 'Courier Prime', courier;
    &.selected{
      color: #1a73e8 !important;
      background-color: #e8f0fe !important;
      border-color: #e8f0fe !important;
    }
    .mdc-button__label {
      line-height: 28px;
    }
    &.disabled{
      color: #bbb;
      &:hover{
        text-decoration: none;
        background-color: transparent !important;
        color: #bbb !important;
        .mat-mdc-button-persistent-ripple::before{
          opacity: 0;
        }
      }
    }
  }
  .mat-mdc-icon-button{
    &.arrows{
      margin: 5px 0px;
      border-radius: 0px !important;
      .material-symbols-sharp{
        font-size: 1rem !important;
      }
      *{
        border-radius: 0px !important;
      }
      &:hover{
        text-decoration: none;
        background-color: #e8f0fe !important;
        border-color: #e8f0fe !important;
        
      }
      &.disabled{
        color: #bbb !important;
        cursor: default;
        .material-symbols-sharp{
          color: #bbb !important;
        }
        &:hover{
          text-decoration: none;
          background-color: transparent !important;
          color: #bbb !important;
          .mat-mdc-button-persistent-ripple::before{
            opacity: 0;
          }
        }
      }
    }
  }
  .page-info{
    display: inline-block;
    vertical-align: middle;
    line-height: 28px;
    border-left: 1px solid #ccc;
    margin: 5px 0px;
    padding: 0 10px 0 15px;
    .number{
      font-size: 1rem;
      font-weight: 400;
      font-family: 'Courier Prime', courier;
    }
  }
}

.grid{
  position: relative;
  padding: 0 1.5em 0 0;
  .absolute-filter {
    position: absolute;
    right: 1.5em;
    top: -36px;
  }
  .ag-theme-quartz{
    --ag-row-hover-color: #fcfcfc;
    --ag-selected-row-background-color: #fcfcfc;
    --ag-icon-size: 13px;
    .ag-root-wrapper{
      border: 1px solid #444;
    }
  }
  .ag-theme-quartz ~ .row { 
    margin: 0px;
    .col-sm-12{
      padding: 0px;
    }
  }

  .ag-sort-indicator-container .ag-sort-indicator-icon {
    padding: 0px;
  }

  .ag-icon {
    vertical-align: middle;
  }

  span.ag-sort-indicator-container {
    position: absolute;
    right: 3px;
  }
  .mat-mdc-icon-button{
    .mat-icon, .material-symbols-sharp{
      width: 24px !important;
      min-width: 24px !important;
      height: 24px !important;
      min-height: 24px !important;
      font-size: 20px !important;
      line-height: 24px !important;
      vertical-align: top;
    }
  }
  .row-empty{
    display: none;
  }
}

.mat-mdc-tab-body-content{
  .grid{
    width: calc(100% - 1.5em);
  }
}

.no-selection-columns{
  .grid{
    padding: 0px;
    width: 100%;
    .absolute-filter {
      right: 0px;
    }
  }
}

.selection-with-checklist{
  .ag-header-row{
    .ag-header-cell.ag-column-first{
      .filter-inputs .grid-label{
        text-align: left;
      }
    }
  }
}

.selection-grid{
  margin: 0px;
  .grid{
    padding: 0px;
    width: 100%;
    .absolute-filter {
      right: 0px;
    }
  }
  .list-selection{
    .grid{
      padding: 0 5px 0 0;
    }
  }
  .ag-header-row{
    .ag-header-cell.ag-column-first{
      .filter-inputs .grid-label{
        text-align: left;
      }
    }
  }
}

app-form-data-view app-data-grid[ng-reflect-show-column-selector="false"]{
  .grid{
    padding: 0;
  }
}

.ag-checkbox-input-wrapper{
  width: 20px;
  height: 20px;
  font-size: 20px;
  line-height: 20px;
  &:focus-within, &:active {
    outline: none;
    box-shadow: none !important;
  }
  &:before {
    content: '';
    font-family: 'Material Icons' !important;
    font-weight: normal;
    font-style: normal;
    border-radius: 0px;
    border: 1px solid #a4c2f4;
    box-shadow: none !important;
    width: 100%;
    display: block;
    height: 100%;
    position: absolute;
  }
  &:after {
    content: '';
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    box-shadow: none;
    color: #15c!important;
    width: 100%;
  }
  &.ag-checked:after {
    content: '\e5ca';
  }
  
}

.data-grid-select-columns{
  position: absolute;
  right: -7px;
  z-index: 99;
  height: 35px;
  text-align: right;
  top: 0px;
  background: #f5f5f5;
  .mat-mdc-icon-button.mat-primary{
    height: 33px;
    .mat-mdc-button-persistent-ripple, .mat-mdc-button-ripple{
      border-radius: 0px;
    }
    .mat-icon,.material-symbols-sharp {
      height: 30px !important;
      min-height: 30px !important;
      line-height: 30px !important;
      font-size: 20px !important;
    }
  }
  mat-form-field.mat-mdc-form-field {
    position: absolute;
    right: 0px;
    top: 30px;
    width: 200px;
    .mdc-text-field--outlined .mdc-notched-outline{
      display: none;
    }
    .mat-mdc-form-field-infix{
      display: none;
    }
    .mat-mdc-form-field-subscript-wrapper{
      display: none;
    }
  }
}

.text-center{
  .ag-cell-wrapper{
    justify-content: center;
    flex-direction: column;
    .ag-selection-checkbox{
      margin: 0px;
    }
  }
}

app-form-data-view {
  .grid{
    padding: 0;
  }
  .ag-cell .ag-selection-checkbox{
    margin-right: 6px !important;
  }
  app-data-grid[ng-reflect-show-column-selector="false"]{
    .grid{
      padding: 0;
    }
  }
}

.ag-aria-description-container{
  display: none !important;
}