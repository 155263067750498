import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { MawUserService } from '@medappsware/maw-auth';

@Component({
  selector: 'app-check-portal',
  standalone: true,
  imports: [RouterModule],
  template: "<div>Checking...</div>",
  styleUrls: ['./../../../../node_modules/@medappsware/shared/themes/maw.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CheckPortalComponent implements OnInit {

  constructor(
    private readonly userService: MawUserService,
    private readonly router: Router,
  ){
    
  }

  ngOnInit(): void {
    let loggedUser = this.userService.user$;
    if(loggedUser?.roles){
      this.setRedirects(loggedUser.roles);
    }
  }

  setRedirects(role: any) {
    if (role && role.length > 0) {
      switch (role[0].name) {
        case 'super_admin': {
          this.router.navigate(['/admin/portal']);
          break;
        }
        case 'assistant_admin': {
          this.router.navigate(['/admin/portal']);
          break;
        }
        case 'tech_admin': {
          this.router.navigate(['/admin/portal']);
          break;
        }
        case 'patient': {
          this.router.navigate(['/patient-portal/lab-results']);
          break;
        }
        case 'physician': {
          this.router.navigate(['/provider-portal/profile']);
          break;
        }
        case 'physician_associate': {
          this.router.navigate(['/provider-portal/profile']);
          break;
        }
        case 'nurse_practitioner': {
          this.router.navigate(['/provider-portal/profile']);
          break;
        }
        default: {
          this.router.navigate(['/']);
          break;
        }
      }
    }else{
      this.router.navigate(['/']);
    }
  }
}