$font_icon: Material Icons;
$font_karla: Karla;
$font_sans: sans-serif;
$font_logo: Lora, serif;
$font_lora: Lora;
$font_roboto_mono: 'Roboto Mono';
$font_oswald: Oswald;
$font_roboto_slab: 'Roboto Slab';
$font_courgette: Courgette;
$font_courier_prime: 'Courier Prime';
$font_0: Material Icons;
$font_1: Lora;
$font_2: sans-serif;
$font_3: Roboto Mono;
$font_4: monospace;
$font_5: sans-serif!important;
$font_6: Karla;
