html, body { height: 100%; }
body { 
    margin: 0; 
    font-family: 'Karla', sans-serif;
    font-size: 0.875em;
    color: #1e293b;
    *{
        padding: 0;
        margin: 0;
        outline: none;
        list-style: none;
    }
}