.nav-brand {
    text-align: center;
    display: block;
    border-bottom: 1px solid #ccc;
    margin-bottom: 15px;
    .nav-logo-link {
        display: block;
        font-family: $font_logo;
        font-size: 32px;
        text-decoration: none;
        color: $color_logo_2;
        letter-spacing: 0.01em;
        line-height: 36px;
        padding: 15px 0;
        span {
          color: $color_logo_1;
        }
    }
}
.header-one{
  background: #000;
  color: #fff;
  padding: 5px 0px;
  img{
    height: 30px;
  }
  a{
    color: #fff;
    font-size: 0.875em;
    cursor: pointer;
    .mat-icon, .material-symbols-sharp{
      font-size: 16px;
      height: 16px;
      width: 16px;
      vertical-align: text-top;
    }
  }
}

.header-two{
  background: #fff;
  padding: 8px 0px;
  border-bottom: 1px solid #ddd;
}
.section-nav {
  display: flex;
  align-items: center;
  flex-direction: row;
  li{
    font-size: 0.875rem;
    padding: 4px 10px;
    font-weight: 500;
    border-radius: 0px;
    margin-left: 10px;
    color: #000;
    cursor: pointer;
    text-transform: uppercase;
    .material-symbols-sharp {
        font-size: 0.875rem;
        vertical-align: middle;
        color: #1155cc;
    }
    &:hover{
        background: #d9e1ec;
        border-radius: 4px;
        .material-symbols-sharp {
            color: #1155cc;
        }
    }
    &.active{
        background: #d9e1ec;
        border-radius: 5px;
        .material-symbols-sharp {
            color: #000;
        }
    }
    &.disabled{
        color: #999;
        cursor: default;
        &:hover{
            background: transparent;
            .material-symbols-sharp {
                color: #999;
            }
        }
    }
    &:first-child{
      margin-left: 0px;
    }
  }
}
.softwareName{
  height: 30px;
  line-height: 30px;
  font-size: 0.75rem;
  font-weight: 600;
  color: #000;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 265px;
  flex: auto;
}
.side-panel, .lab-panel{
  position: fixed;
  top: 85px;
  left: -25em;
  height: calc(100vh - 85px);
  min-width: 17em;
  max-width: 25em;
  z-index: 2000;
  background-color: #fff;
  overflow: auto;
  transition: all 0.3s ease;
  border-right: 1px solid #ccc;
  &.show{
      left: 0;
  }
  ul{
      margin: 10px 10px 0px 10px;
      li{
          width: 100%;
          text-align: left;
          height: 30px;
          line-height: 30px;
          cursor: pointer;
          border: 1px solid #ccc;
          margin: 0 0 10px 0;
          font-size: 0.75rem;
          background: #fff;
          padding: 0px 5px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          &:hover, &.active{
              background: #f0f5f9;
              color: #1155cc;
              border: 1px solid #1155cc;
          }
      }
  }
}

.absolute-btns {
  position: absolute;
  right: 24px;
  z-index: 100;
}